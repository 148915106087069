let hamburger = document.getElementById("js-hamburger");
let nav = document.getElementById("js-sp-nav");

hamburger.addEventListener("click", () => {
  hamburger.classList.toggle("js-active-hamburger");
  nav.classList.toggle("js-active-sp-nav");

  document.body.style.overflow = hamburger.classList.contains(
    "js-active-hamburger"
  )
    ? "hidden"
    : "auto";
});
