import { gsap } from "gsap";

window.addEventListener("DOMContentLoaded", () => {
  let accordions = document.querySelectorAll(".c-accordion-trigger");
  let textBoxs = document.querySelectorAll(".c-accordion-text-box");
  let lines = document.querySelectorAll(".c-accordion-icon");

  // トリガー
  accordions.forEach((accordion, index) => {
    accordion.addEventListener("click", () => {
      const line = lines[index];
      const textBox = textBoxs[index];
      toggleAccordion(textBox);
      lineAccordion(line);
    });
  });

  // テキストボックス
  function toggleAccordion(textBox) {
    if (textBox.style.height === "0px" || textBox.style.height === "") {
      gsap.to(textBox, {
        height: "auto",
        marginBottom: "30px",
        opacity: 1,
        ease: "power2.i",
      });
    } else {
      gsap.to(textBox, {
        height: 0,
        marginBottom: 0,
        opacity: 0,
        ease: "power2.inOut",
      });
    }
  }

  // line
  function lineAccordion(line) {
    if (
      line.style.transform === "rotate(0deg)" ||
      line.style.transform === "rotate(-180deg)"
    ) {
      gsap.to(line, {
        rotation: 0,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(line, {
        rotation: -180,
        ease: "power2.inOut",
      });
    }
  }
});
