import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function parallax() {
  // parallax - gsap;
  gsap.utils.toArray(".c-parallax").forEach((wrap) => {
    const y = wrap.getAttribute("data-y") || -50;
    gsap.to(wrap, {
      y: y,
      scrollTrigger: {
        trigger: wrap,
        start: "top bottom",
        end: "bottom top",
        scrub: 0.5,
      },
    });
  });
}
