import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

document.querySelectorAll(".c-zoom-in-trigger").forEach((element) => {
  gsap.fromTo(
    ".c-zoom-in",
    { scale: 1 },
    {
      scale: 1.2,
      scrollTrigger: {
        trigger: element,
        start: "top top",
        end: "bottom top",
        scrub: 2,
        // markers: true,
      },
    }
  );
});
