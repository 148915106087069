// /* ページ内リンクでの遷移時にヘッダー分下げる */
// export function anchorScrollPosition() {
//   document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
//     anchor.addEventListener("click", function (event) {
//       event.preventDefault();

//       const target = document.querySelector(this.getAttribute("href"));
//       const headerHeight = document.querySelector(".l-header").offsetHeight;

//       window.scrollTo({
//         top: target.offsetTop - (headerHeight + 20),
//         left: 0,
//         behavior: "smooth",
//       });
//     });
//   });
// }
