import { anchorScrollPosition } from "./anchorScrollPosition";
import { switchViewport } from "./responsive";
import { parallax } from "./parallax";
import { zoomOut } from "./zoom-out";
import { zoomIn } from "./zoom-in";
import { hamburger } from "./hamburger";
import { accordion } from "./accordion";
import { nav } from "./nav";

window.addEventListener("DOMContentLoaded", () => {
  switchViewport();
  anchorScrollPosition();
  parallax();
  zoomOut();
  zoomIn();
  hamburger();
  accordion();
  nav();
});
