import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// テキスト
gsap.fromTo(
  ".c-zoom-out",
  { scale: 1.2 },

  {
    scale: 1,

    scrollTrigger: {
      trigger: ".l-footer-parallax",
      start: "top center",
      end: "center center",
      scrub: 2,
      // markers: true,
    },
  }
);
