let titles = document.querySelectorAll(".l-block__title");
let lists = document.querySelectorAll(".l-block__nav-list");
let navHtml = "";
let count = 1;

// ナビゲーションリンクの生成
titles.forEach((title) => {
  let navText = title.textContent;
  let navLink = `#nav${count}`;
  title.id = `nav${count}`;

  navHtml += `
            <li class="p-service-single-nav__list-item nav${count}">
                <a class="p-service-single-nav__list-item__link" href="${navLink}">${navText}</a>
            </li>
        `;

  count++;
});

lists.forEach((list) => {
  list.innerHTML = navHtml;
});
